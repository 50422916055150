<template>
  <AppContainer v-if="visibleItems?.length" class="px-4 md:px-6 3xl:px-12">
    <div
      v-if="advertisingLabelVisible"
      class="flex items-baseline 3xl:max-w-[101.25rem] mx-auto gap-x-4 mb-4 lg:gap-x-6 2xl:gap-x-8 lg:mb-8 opacity-50"
    >
      <div class="h-px bg-gradient-brand-magenta w-full"></div>
      <span class="flex-shrink text-xs lg:text-sm xl:text-base font-normal">
        Inzerce
      </span>
    </div>
    <h3
      v-if="header"
      class="floor-headline-section mb-2 lg:mb-4 3xl:mb-6 3xl:max-w-[101.25rem] mx-auto"
    >
      {{ header }}
    </h3>
    <div class="flex flex-wrap justify-center">
      <component
        :is="getComponentType(visibleItems?.[0])"
        v-bind="{
          ...(getComponentType(visibleItems?.[0]) === 'a'
            ? { href: getLinkUrl(visibleItems?.[0]) }
            : { to: getLinkUrl(visibleItems?.[0]) }),
        }"
        :target="getLinkTarget(visibleItems?.[0])"
        class="relative block w-full max-w-full 3xl:max-w-[101.25rem]"
      >
        <img
          :src="resolveImageSrcset(visibleItems?.[0])[0]"
          class="sm:hidden"
          :title="visibleItems?.[0]?.header"
          loading="lazy"
        />
        <img
          :src="resolveImageSrcset(visibleItems?.[0])[1]"
          class="<sm:hidden xl:hidden"
          :title="visibleItems?.[0]?.header"
          loading="lazy"
        />
        <img
          :src="resolveImageSrcset(visibleItems?.[0])[2]"
          class="<xl:hidden"
          :title="visibleItems?.[0]?.header"
          loading="lazy"
        />
      </component>
    </div>
  </AppContainer>
</template>

<script setup lang="ts">
import { NuxtLink } from "#components";
import { onMounted } from "vue";
import AppContainer from "~/components/ui/AppContainer.vue";
import { useFetchAdvertisingData } from "~/composables/useFetchCraftData";

const props = defineProps({
  header: {
    type: String,
    default: "",
  },
  advertisingLabelVisible: {
    type: Boolean,
    default: false,
  },
  items: {
    type: Array,
    default: () => [],
  },
});

const route = useRoute();

const propItems = computed(() => {
  return (props.items ?? [])?.filter((item: any) => {
    const { postDate, expiryDate } = item;
    const now = new Date();
    if (postDate && expiryDate) {
      return now >= new Date(postDate) && now <= new Date(expiryDate);
    }
    return true;
  });
});

const { data: siteEntries, refresh: refreshSiteEntries } =
  await useFetchAdvertisingData();

const previewToken = useState("previewToken", () => null);

onMounted(() => {
  if (previewToken?.value) {
    refreshSiteEntries();
  }
});

const activeGlobalItems = computed(() => {
  return siteEntries?.value?.advertisingEntries?.filter?.((item: any) => {
    const { postDate, expiryDate } = item;
    const now = new Date();
    if (postDate && expiryDate) {
      return now >= new Date(postDate) && now <= new Date(expiryDate);
    }
    return true;
  });
});

const visibleItems = computed(() => {
  return (
    propItems.value.length ? propItems.value : activeGlobalItems.value
  )?.sort?.((a, b) => {
    try {
      return new Date(a.postDate).getTime() - new Date(b.postDate).getTime();
    } catch (e) {}
    return 0;
  });
});

const resolveImageSrcset = (item: any) => {
  const lang = route.params?.lang;
  if (lang === "en") {
    return [
      item?.advertisingImageMobiletEn?.[0]?.url ??
        item?.advertisingImageTabletEn?.[0]?.url ??
        item?.advertisingImageDesktopEn?.[0]?.url ??
        item?.advertisingImageMobile?.[0]?.url ??
        item?.advertisingImageTablet?.[0]?.url ??
        item?.advertisingImageDesktop?.[0]?.url,
      item?.advertisingImageTabletEn?.[0]?.url ??
        item?.advertisingImageDesktopEn?.[0]?.url ??
        item?.advertisingImageTablet?.[0]?.url ??
        item?.advertisingImageDesktop?.[0]?.url,
      item?.advertisingImageDesktopEn?.[0]?.url ??
        item?.advertisingImageDesktop?.[0]?.url,
    ];
  }
  return [
    item?.advertisingImageMobile?.[0]?.url ??
      item?.advertisingImageTablet?.[0]?.url ??
      item?.advertisingImageDesktop?.[0]?.url,
    item?.advertisingImageTablet?.[0]?.url ??
      item?.advertisingImageDesktop?.[0]?.url,
    item?.advertisingImageDesktop?.[0]?.url,
  ];
};

const getComponentType = (item) => {
  return item?.linkElement?.[0]?.openInNewWindow ||
    item?.linkElement?.[0]?.externalUrl
    ? "a"
    : NuxtLink;
};
const getLinkTarget = (item) => {
  return item?.linkElement?.[0]?.openInNewWindow ? "_blank" : "_self";
};

const getLinkUrl = (item) => {
  return (
    item?.linkElement?.[0]?.targetEntry?.[0]?.uri ??
    item?.linkElement?.[0]?.externalUrl
  );
};
</script>
