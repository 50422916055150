<template>
  <div>
    <slot name="container" :active="expandedInternal" :toggle="toggle">
      <div
        :style="expandedInternal ? 'max-height: none;' : maxHeight"
        :class="containerClass"
      >
        <slot name="header" :active="expandedInternal" :toggle="toggle" />
        <div
          ref="content"
          class="transition-all duration-500"
          :class="[
            contentClass,
            { 'overflow-hidden': animating || !expandedInternal },
          ]"
        >
          <slot name="content" :active="expandedInternal" />
        </div>
        <slot name="footer" :active="expandedInternal" :toggle="toggle" />
      </div>
    </slot>
  </div>
</template>
<script>
export default {
  model: {
    prop: "expanded",
    event: "change",
  },
  props: {
    containerClass: {
      type: String,
      default: "",
    },
    contentSelector: {
      type: String,
      default: "",
    },
    expanded: {
      type: Boolean,
      default: true,
    },
    maxHeight: {
      type: String,
      default: "400px",
    },
    contentClass: {
      type: String,
      default: "",
    },
  },
  emits: ["change"],
  data() {
    return {
      expandedInternal: false,
      contentElement: null,
      contentStyle: null,
      watchState: false,
      animating: false,
    };
  },
  watch: {
    expandedInternal(val) {
      this.resolveState(val);
      if (this.watchState) {
        this.watchState = false;
        this.$emit("change", val);
        this.$nextTick(() => {
          this.watchState = true;
        });
      }
    },
    expanded(val) {
      if (this.watchState) {
        this.watchState = false;
        this.expandedInternal = val;
        this.$nextTick(() => {
          this.watchState = true;
        });
      }
    },
  },
  created() {
    this.expandedInternal = this.expanded;
  },
  mounted() {
    if (this.contentSelector) {
      this.contentElement = this.$el.querySelector(this.contentSelector);
    } else {
      this.contentElement = this.$refs.content;
    }
    this.$nextTick(() => {
      // initial state
      this.resolveState(this.expandedInternal, false);
      this.watchState = true;
    });
  },
  methods: {
    toggle() {
      this.expandedInternal = !this.expandedInternal;
    },
    resolveState(state, smooth = true) {
      if (state) {
        this.expand(smooth);
      } else {
        this.collapse(smooth);
      }
    },
    expand(smooth = true) {
      this.animating = true;
      if (this.contentElement) {
        if (smooth) {
          this.contentElement.style.display = "";
          requestAnimationFrame(() => {
            if (this.contentElement.scrollHeight) {
              this.contentElement.style.height = `${this.contentElement.scrollHeight}px`;
              this.contentElement.addEventListener(
                "transitionend",
                () => {
                  if (this.expandedInternal) {
                    this.contentElement.style.height = "auto";
                  }
                  requestAnimationFrame(() => (this.animating = false));
                },
                { once: true },
              );
            } else {
              this.contentElement.style.height = "auto";
            }
          });
        } else {
          this.contentElement.style.height = "auto";
          this.contentElement.style.display = "";
          requestAnimationFrame(() => (this.animating = false));
        }
      }
    },
    collapse(smooth = true) {
      this.animating = true;
      if (this.contentElement) {
        if (smooth) {
          const sectionHeight = this.contentElement.scrollHeight;
          const elementTransition = this.contentElement.style.transition;
          this.contentElement.style.transition = "";
          requestAnimationFrame(() => {
            this.contentElement.style.height = `${sectionHeight}px`;
            this.contentElement.style.transition = elementTransition;
            requestAnimationFrame(() => {
              this.contentElement.style.height = `${0}px`;
              this.contentElement.addEventListener(
                "transitionend",
                () => {
                  if (!this.expandedInternal) {
                    this.contentElement.style.display = "none";
                  }
                  requestAnimationFrame(() => (this.animating = false));
                },
                { once: true },
              );
            });
          });
        } else {
          this.contentElement.style.height = `${0}px`;
          this.contentElement.style.display = "none";
          requestAnimationFrame(() => (this.animating = false));
        }
      }
    },
  },
};
</script>
