<script setup lang="ts">
import { computed } from "#imports";
import dashedLine from "~/assets/svg/dashed-line.svg?raw";

const props = defineProps({
  image: {
    type: Object,
    default: null,
  },
  title: {
    type: String,
    default: null,
  },
  description: {
    type: String,
    default: null,
  },
  label: {
    type: String,
    default: null,
  },
  variant: {
    type: String,
    default: "carousel",
  },
});

const VARIANTS = {
  carousel: {
    container:
      "flex-1 flex-col items-center text-center p-4 md:p-6 lg:p-8 3xl:p-12",
    imageContainer: "w-full",
    imageWrapper:
      "relative w-full aspect-[4/5] md:mx-[22px] my-2.5 lg:my-[13px] 3xl:my-2.5 mb-4 lg:mb-8 max-w-30 md:max-w-28 lg:max-w-[143px] 3xl:max-w-[212px]",
    showBorder: false,
    image: "absolute inset-0 w-full h-full object-contain",
    contentWrapper: "",
    title:
      "text-lg leading-6 lg:text-2xl lg:leading-8 3xl:text-4xl 3xl:leading-10",
    description: "floor-text text-interface-transparent-white",
  },
  rows: {
    container: "w-full md:flex-1 md:flex-col md:items-center md:text-center",
    imageContainer: "relative w-2/5 p-4 md:w-full lg:p-6 3xl:p-8 md:flex-1",
    imageWrapper:
      "relative flex-1 aspect-[5/4] md:mx-[22px] md:my-2.5 lg:m-[13px] 3xl:mx-8",
    showBorder: false,
    image: "absolute inset-0 w-full h-full object-contain",
    contentWrapper: "p-4 lg:p-6 md:pt-0 lg:pt-0 3xl:p-8 3xl:pt-0",
    title:
      "text-base leading-5 lg:text-lg lg:leading-6 3xl:text-xl 3xl:leading-7",
    description:
      "text-primary-lighter text-2xs leading-4 lg:text-sm lg:leading-5 3xl:text-base 3xl:leading-6 mb-3 md:mb-6 3xl:mb-9",
  },
};

const theme = computed(() => {
  if (props.variant in VARIANTS) return VARIANTS[props.variant];
  return VARIANTS["carousel"];
});
</script>

<template>
  <div
    class="bg-interface-transparent-blue mb-3 lg:mb-4 3xl:mb-6 flex rounded"
    :class="theme.container"
  >
    <div class="flex items-center justify-center" :class="theme.imageContainer">
      <div :class="theme.imageWrapper">
        <img
          v-if="image"
          :src="image.url"
          :alt="image.alt"
          class="w-full"
          :class="theme.image"
        />
      </div>
      <div
        v-if="theme.showBorder"
        class="absolute bottom-0 left-0 right-0 w-full h-px"
        v-html="dashedLine"
      />
    </div>
    <div
      class="flex-1 flex flex-col 3xl:max-w-[461px]"
      :class="theme.contentWrapper"
    >
      <h3
        v-if="title"
        class="mb-3 lg:mb-4 3xl:mb-6"
        :class="theme.title"
        v-html="title"
      />
      <p
        v-if="description"
        class="font-secondary"
        :class="theme.description"
        v-html="description"
      />
      <span
        v-if="label"
        class="text-secondary-magenta-lighter mt-auto text-base leading-5 3xl:text-xl 3xl:leading-7"
      >
        {{ label }}
      </span>
    </div>
  </div>
</template>
