import { ref } from "#imports";

export const useHeroSwiper = () => {
  const swiperInstance = ref(null);
  const progressBarMobile = ref(null);
  const progressBarDesktop = ref(null);

  const swiperProgressBar = reactive({
    slideTime: 10000,
    progressValue: 1,
  });

  const initSwiperInstance = (instance) => {
    swiperInstance.value = instance;
  };

  const onAutoplayTimeLeft = (s, time, progress) => {
    swiperProgressBar.progressValue = (1 - progress) * 100;
    const activeProgressBarMobile =
      progressBarMobile.value?.[swiperInstance.value?.realIndex ?? 0] ?? null;
    if (activeProgressBarMobile) {
      activeProgressBarMobile.style.height =
        swiperProgressBar.progressValue + "%";
    }
    const activeProgressBarDesktop =
      progressBarDesktop.value?.[swiperInstance.value?.realIndex ?? 0] ?? null;
    if (activeProgressBarDesktop) {
      activeProgressBarDesktop.style.width =
        swiperProgressBar.progressValue + "%";
    }
  };

  const resetProgressBar = () => {
    if (progressBarMobile.value?.length > 0) {
      progressBarMobile.value.forEach((element) => {
        element.style.height = 0;
      });
    }
    if (progressBarDesktop.value?.length > 0) {
      progressBarDesktop.value.forEach((element) => {
        element.style.width = 0;
      });
    }
  };

  const slideTo = (index) => {
    if (
      !swiperInstance.value?.slides?.length ||
      swiperInstance.value?.realIndex === index
    )
      return;
    swiperInstance.value.slideTo(index);
  };

  return {
    swiperInstance,
    progressBarMobile,
    progressBarDesktop,
    swiperProgressBar,
    initSwiperInstance,
    onAutoplayTimeLeft,
    resetProgressBar,
    slideTo,
  };
};
