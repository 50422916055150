<script setup lang="ts">
import appStoreBadgeCZ from "~/assets/svg/app-store-badge-cz.svg?url";
import appStoreBadgeEN from "~/assets/svg/app-store-badge-en.svg?url";
import googlePlayBadgeCZ from "~/assets/images/google-play-badge-cz.png";
import googlePlayBadgeEN from "~/assets/images/google-play-badge-en.png";

const props = defineProps({
  variant: {
    type: String,
    default: "appStore",
  },
});

const isAppStore = () => props.variant === "appStore";
</script>

<template>
  <template v-if="isAppStore()">
    <!--  Todo: Add Route for App Store-->
    <a href="#" target="_blank">
      <template v-if="$route.params?.lang == 'en'">
        <img
          :src="appStoreBadgeEN"
          class="h-11 lg:h-12 w-auto"
          alt="App Store"
        />
      </template>
      <template v-else>
        <img
          :src="appStoreBadgeCZ"
          class="h-11 lg:h-12 w-auto"
          alt="App Store"
        />
      </template>
    </a>
  </template>
  <template v-else>
    <!--  Todo: Add Route for Google play-->
    <a href="#" target="_blank">
      <template v-if="$route.params?.lang == 'en'">
        <img
          :src="googlePlayBadgeEN"
          class="h-11 lg:h-12 w-auto"
          alt="App Store"
        />
      </template>
      <template v-else>
        <img
          :src="googlePlayBadgeCZ"
          class="h-11 lg:h-12 w-auto"
          alt="App Store"
        />
      </template>
    </a>
  </template>
</template>
