import { ref, onMounted, watchEffect } from "vue";
import type { Ref } from "vue";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { createPopper } from "@popperjs/core";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import type { StrictModifiers } from "@popperjs/core";

export function usePopper(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  options?,
  {
    onBeforeCreate = () => null,
    onCreate = () => null,
    onBeforeDestroy = () => null,
    onDestroy = () => null,
  } = {},
) {
  const reference: Ref<{ el: HTMLElement } | HTMLElement> = ref(
    {} as HTMLElement,
  );
  const popper: Ref<{ el: HTMLElement } | HTMLElement> = ref({} as HTMLElement);

  onMounted(() => {
    watchEffect((onInvalidate) => {
      if (!popper.value) return;
      if (!reference.value) return;

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const popperEl = popper.value.$el || popper.value.el || popper.value;

      const referenceEl =
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        reference.value.$el || reference.value.el || reference.value;

      if (!referenceEl?.getBoundingClientRect) return;
      if (!popperEl?.getBoundingClientRect) return;

      onBeforeCreate();
      const popperInstance = createPopper<StrictModifiers>(
        referenceEl,
        popperEl,
        options,
      );
      const { destroy } = popperInstance;
      onCreate();

      onInvalidate(() => {
        onBeforeDestroy();
        destroy();
        onDestroy();
      });
    });
  });

  return [reference, popper];
}
