import { ref } from "vue";
import { usePopper } from "~/utils/usePopper";

export const useScreeningTooltip = (popperOptions) => {
  let _timer = null;
  const TIMEOUT = 100;
  const hoveredScreening = ref(null);

  const [hoverTriggerElement, hoveredScreeningPopup] = usePopper(popperOptions);

  const setActiveScreening = (
    { screening, movie, mainMovie, event },
    cinemaHalls,
  ) => {
    clearTimeout(_timer);
    (_timer = setTimeout(() => {
      hoveredScreening.value = {
        screening,
        movie,
        mainMovie,
        hoverTriggerElement: event?.target,
        hall: cinemaHalls?.cinemaHallsEntries?.find?.(
          (hall) => hall?.ObjectId === screening?.ObjectId,
        ),
      };

      hoverTriggerElement.value = event?.target;
    })),
      0;
  };

  const closeActiveScreeningTooltip = () => {
    clearTimeout(_timer);
    _timer = setTimeout(() => {
      hoveredScreening.value = null;
    }, TIMEOUT);
  };

  const holdActiveScreeningTooltip = () => {
    clearTimeout(_timer);
  };

  onUnmounted(() => {
    clearTimeout(_timer);
    _timer = null;
  });
  return {
    hoveredScreening,
    hoveredScreeningPopup,
    setActiveScreening,
    closeActiveScreeningTooltip,
    holdActiveScreeningTooltip,
  };
};
