<script setup>
const props = defineProps({
  spacingTop: {
    type: Boolean,
    default: true,
  },
  spacingBottom: {
    type: Boolean,
    default: true,
  },
  variant: {
    type: String,
    default: "padding",
  },
});

const VARIANTS = {
  padding: {
    top: "pt-4 lg:pt-8 2xl:pt-10",
    bottom: "pb-4 lg:pb-8 2xl:pb-10",
  },
  margin: {
    top: "mt-4 lg:mt-8 2xl:mt-10",
    bottom: "mb-4 lg:mb-8 2xl:mb-10",
  },
};

const spacingVariant = computed(() => {
  const variant = props.variant.toLowerCase();
  if (variant in VARIANTS) {
    return VARIANTS[variant];
  }
  return VARIANTS["padding"];
});
</script>

<template>
  <div
    :class="{
      [spacingVariant.top]: spacingTop,
      [spacingVariant.bottom]: spacingBottom,
    }"
  >
    <slot></slot>
  </div>
</template>
