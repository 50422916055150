<script setup lang="ts">
import videojs from "video.js";
import "video.js/dist/video-js.css";
import { computed } from "#imports";

const props = defineProps({
  video: {
    type: Object,
    default: () => ({}),
    required: true,
  },
  subtitles: {
    type: Object,
    default: () => ({}),
    required: true,
  },
  hasExternalSubtitles: {
    type: Boolean,
    default: false,
  },
  externalSubtitles: {
    type: String,
    default: null,
  },
});

const { public: config } = useRuntimeConfig();
const videoPlayer = ref();
const player = ref(null);

const subtitles = computed(() => {
  let subtitles = {};
  const DOMAIN_URL = `${config.CMS_DOMAIN}/actions/movie/subtitles/get-by-url?url=`;
  if (props.hasExternalSubtitles && props.externalSubtitles) {
    subtitles = {
      tracks: [
        {
          kind: "captions",
          label: "Czech",
          srcLang: "cz",
          src: DOMAIN_URL + props.externalSubtitles.replace(".srt", ".vtt"),
          default: true,
        },
      ],
    };
  } else if (props.subtitles?.[0]?.url) {
    subtitles = {
      tracks: [
        {
          kind: "captions",
          label: props.subtitles?.[0]?.title ?? "Czech",
          srcLang: "cz",
          src: DOMAIN_URL + props.subtitles[0].url.replace(".srt", ".vtt"),
          default: true,
        },
      ],
    };
  }

  return subtitles;
});

onMounted(() => {
  if (props.video?.length > 0) {
    player.value = videojs(videoPlayer.value, {
      controls: true,
      language: "cs",
      html5: {
        nativeTextTracks: false,
      },
      sources: [
        {
          src: props.video[0].url,
          type: props.video[0].mimeType,
        },
      ],
      ...subtitles.value,
    });
  }
});

onBeforeUnmount(() => {
  if (player.value) {
    player.value?.dispose?.();
  }
});
</script>

<template>
  <div class="relative aspect-16/10 w-full max-h-full">
    <div class="absolute object-cover inset-0 w-full h-full">
      <video ref="videoPlayer" class="video-js w-full h-full">
        <source :src="video[0].url" :type="video[0].mimeType" />
      </video>
    </div>
  </div>
</template>
