export function useCinemaProgramme() {
  const atLeastBreakpoint = inject("atLeastBreakpoint", () => () => false);

  const initEqualHeight = () => {
    const targets = document.querySelectorAll("[data-movie-row]");
    const VERTICAL_SAFE_ZONE = 28;
    targets.forEach((target) => {
      target.style.height = "auto";
      const id = target.dataset.movieRow;
      let targetHeight = Math.ceil(target.clientHeight);
      if (atLeastBreakpoint("md")) {
        target.style.height = `${targetHeight + VERTICAL_SAFE_ZONE}px`;
      }
      const cols = document.querySelectorAll(`[data-movie-row-target="${id}"]`);
      cols.forEach((col) => {
        col.style.height = "auto";
        if (atLeastBreakpoint("md") && col.clientHeight > targetHeight) {
          targetHeight = Math.ceil(col.clientHeight);
        }
      });
      cols.forEach((col) => {
        if (atLeastBreakpoint("md")) {
          target.style.height = `${targetHeight + VERTICAL_SAFE_ZONE}px`;
          col.style.height = `${targetHeight + VERTICAL_SAFE_ZONE}px`;
        }
      });
    });
  };

  return {
    initEqualHeight,
  };
}
