import { computed, defineAsyncComponent, ref } from "#imports";

export const useHeroBlock = () => {
  const lastVisitedLang = useCookie("lastVisitedLang");
  const route = useRoute();

  const showModal = ref([]);

  const iconPlay = computed(() =>
    defineAsyncComponent(() => import("~/assets/svg/play.svg")),
  );

  const TrailerModal = defineAsyncComponent(() =>
    import("~/components/TrailerModal.vue"),
  );

  const getMovieDetailUrl = (movie) => {
    return `/${route.params?.lang}/${route.params?.cinemaslug}/${
      movie?.[0]?.uri ?? ""
    }`;
  };

  const getItemTitle = (item) => {
    if (item?.title) {
      return item?.title;
    }
    return item?.movie?.[0]?.[`${lastVisitedLang.value ?? "cz"}Title`] ?? null;
  };

  const isContentAlignToRight = (item) => item?.contentPosition === "right";

  const getTrailer = (movie) => movie?.[0]?.CSFD?.[0]?.Videos?.[0] ?? null;

  return {
    iconPlay,
    TrailerModal,
    showModal,
    getMovieDetailUrl,
    getItemTitle,
    getTrailer,
    isContentAlignToRight,
  };
};
