<script setup lang="ts">
import { computed, getSiteHandle, onMounted } from "#imports";

import AppMovieSwiper from "~/components/AppMovieSwiper.vue";

import {
  useFetchCinemaScreeningsData,
  useFetchSpecificMoviesData,
} from "~/composables/useFetchCraftData";
import {
  sortOverrideMoviesByOrderAndAlphabetically,
  transformOverrideMoviesData,
  setUniqueCustomIndexOrder,
  implementMoviesWithOverriedOrder,
} from "~/utils/movies";

const SCREENING_TYPES = {
  screening: "screening",
  upcoming: "upcoming",
};

const props = defineProps({
  header: {
    type: String,
    default: "",
  },
  moviesOrderOverride: {
    type: Array,
    default: () => [],
  },
  movieScreeningsType: {
    type: String,
    default: "screening",
  },
  cinemaHallTypeId: {
    type: String,
    default: null,
  },
  cinemaHallTagCodes: {
    type: Array,
    default: () => [],
  },
});

const { data: localMovies, refresh: refreshLocalMovies } =
  await useFetchSpecificMoviesData();
const startOfToday = new Date().setHours(0, 0, 0, 0);

const { data: localScreenings, refresh: refreshLocalScreenings } =
  await useFetchCinemaScreeningsData({
    site: getSiteHandle(),
    Start: `>=${new Date(startOfToday).toISOString()}`,
  });

let unique = (a: [], t: any = {}) => a.filter((e) => !(t[e] = e in t));

const movieOrderOverrides = computed(() => {
  return setUniqueCustomIndexOrder(
    transformOverrideMoviesData(props.moviesOrderOverride).sort(
      sortOverrideMoviesByOrderAndAlphabetically,
    ),
  );
});

const purchaseAbleMovies = computed<string[]>(() => {
  const ids = unique(
    localScreenings?.value?.scheduledEventsEntries?.map?.(
      (screening: { [key: string]: any }) => screening.TitleId,
    ) ?? [],
  );
  localMovies?.value?.moviesInCinemasAvailabilityEntries?.forEach?.(
    (movie: { [key: string]: any }) => {
      if (ids.includes(movie.MovieId) && !ids.includes(movie.MovieGroupId)) {
        ids.push(movie.MovieGroupId);
      }
    },
  );
  return ids;
});

const getMovieTags = (item) => {
  // If is not array than an empty object
  const Properties = Array.isArray(item?.Properties)
    ? item.Properties.reduce(
        (acc, prop) => ({ ...acc, [prop.Code]: prop.Name }),
        {},
      )
    : item?.Properties;

  const tagCodes = new Set(
    Properties ? Object.keys(Properties).map(Number) : [],
  );

  item.movie?.forEach((subMovie) => {
    subMovie.categoryTags?.forEach((category) => {
      tagCodes.add(Number(category.tagCode));
    });
  });

  return tagCodes;
};

const movies = computed(() => {
  const screeningsProperties = {};
  localScreenings?.value?.scheduledEventsEntries?.forEach?.(
    (screening: { [key: string]: any }) => {
      if (screening.Properties) {
        screening.Properties.forEach((prop) => {
          if (!screeningsProperties[screening.TitleId]) {
            screeningsProperties[screening.TitleId] = {};
          }
          screeningsProperties[screening.TitleId][prop.Code] = prop.Name;
        });
      }
    },
  );

  const moviesWithScreeningProperties =
    localMovies.value?.moviesInCinemasAvailabilityEntries.map((movie) => {
      const Properties =
        screeningsProperties[movie.MovieId] ?? movie.Properties ?? {};

      return { ...movie, Properties };
    });

  const today = new Date();
  const alreadyShownMainMovies: string[] = [];
  if (!moviesWithScreeningProperties) {
    return [...Array(7)].map(() => ({ placeholder: true }));
  }
  let resMovies = moviesWithScreeningProperties
    ?.filter?.((movie: { [key: string]: any }) =>
      props.movieScreeningsType === SCREENING_TYPES.screening
        ? purchaseAbleMovies.value?.includes?.(movie?.MovieId)
        : !purchaseAbleMovies.value?.includes?.(movie?.MovieId),
    )
    ?.filter((movie) => {
      if (props.cinemaHallTypeId) {
        return (
          getMovieTags(movie).has(Number(props.cinemaHallTypeId)) ||
          (props.cinemaHallTagCodes.length > 0 &&
            props.cinemaHallTagCodes.some((tagCode) =>
              getMovieTags(movie).has(Number(tagCode)),
            ))
        );
      }
      return true;
    })
    ?.filter?.((movie) => {
      if (props.movieScreeningsType === SCREENING_TYPES.screening) {
        return true;
      }
      return !(
        today > new Date(movie.Premiere || movie?.movie?.Premiere).getTime()
      );
    })
    ?.filter?.(
      (movie: { [key: string]: any }) =>
        !alreadyShownMainMovies.includes(
          movie?.movieGroup?.[0]?.MovieId || movie?.MovieId,
        ) &&
        alreadyShownMainMovies.push(
          movie?.movieGroup?.[0]?.MovieId || movie?.MovieId,
        ) &&
        true,
    );
  const alreadyPremieredMovieIndex = resMovies.findIndex((movie) =>
    movie?.Premiere ? new Date(movie?.Premiere) <= new Date() : false,
  );
  resMovies = [
    ...resMovies.slice(0, alreadyPremieredMovieIndex).reverse(),
    ...resMovies.slice(alreadyPremieredMovieIndex),
  ];

  return implementMoviesWithOverriedOrder(resMovies, movieOrderOverrides.value);
});

const isMoviePremiering = (movie) => {
  return localScreenings?.value?.scheduledEventsEntries?.some(
    (screening) =>
      [movie?.MovieId, movie?.movie?.groupId].includes(screening?.TitleId) &&
      screening?.Properties?.some?.((prop) => Number(prop?.Code) === 8),
  );
};

const previewToken = useState("previewToken", () => null);

onMounted(async () => {
  if (previewToken?.value !== null) {
    await refreshLocalMovies();
  }
  await refreshLocalScreenings();
});
</script>

<template>
  <AppMovieSwiper
    :header="header"
    :movies="movies"
    :is-purchase-able="movieScreeningsType === SCREENING_TYPES.screening"
    :is-movie-premiering="isMoviePremiering"
  />
</template>
