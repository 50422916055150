<template>
  <span
    class="font-medium"
    :class="includeLinebreak ? 'whitespace-pre-line' : 'whitespace-nowrap'"
    v-html="
      dayLabel
        .map((word, index) =>
          !index
            ? `${word}\n`
            : includeTime && dayLabel.length - 1 === index
            ? ` — ${word}`
            : word,
        )
        .join(' ')
        .replace(' — 00:00', '')
    "
  />
</template>
<script setup>
import { dayLabelFormatter } from "~/utils/common";
const props = defineProps({
  day: {
    type: [Object, Number, String],
    required: true,
  },
  wholeDate: {
    type: Boolean,
    default: false,
  },
  includeLinebreak: {
    type: Boolean,
    default: false,
  },
  includeTime: {
    type: Boolean,
    default: false,
  },
  noWeekDay: {
    type: Boolean,
    default: false,
  },
  fullMonthFormat: {
    type: Boolean,
    default: false,
  },
  today: {
    type: String,
    default: null,
  },
  tomorrow: {
    type: String,
    default: null,
  },
});
const dayLabel = computed(() =>
  dayLabelFormatter(props.day, {
    wholeDate: props.wholeDate,
    today: props.today,
    tomorrow: props.tomorrow,
    includeTime: props.includeTime,
    noWeekDay: props.noWeekDay,
    fullMonthFormat: props.fullMonthFormat,
  }).split(/&nbsp;|\s/),
);
</script>
