import { ref } from "vue";
import { usePopper } from "~/utils/usePopper";

export const useScreeningCinemaHallTooltip = (popperOptions) => {
  let _timer = null;
  const TIMEOUT = 100;
  const hoveredCinemaHall = ref(null);
  const showBackground = ref(false);

  const [hoverCinemaHallTriggerElement, hoveredCinemaHallPopup] =
    usePopper(popperOptions);

  const setActiveCinemaHallTooltip = ({ movie, event }) => {
    clearTimeout(_timer);
    (_timer = setTimeout(() => {
      hoveredCinemaHall.value = {
        movie,
        hoverCinemaHallTriggerElement: event?.target,
      };

      hoverCinemaHallTriggerElement.value = event?.target;
    })),
      0;
  };

  const closeCinemaHallTooltip = () => {
    clearTimeout(_timer);
    _timer = setTimeout(() => {
      hoveredCinemaHall.value = null;
    }, TIMEOUT);
  };

  const holdCinemaHallTooltip = () => {
    clearTimeout(_timer);
  };

  watch(
    () => hoveredCinemaHall.value,
    (value) => {
      if (value) {
        setTimeout(() => {
          showBackground.value = true;
        }, 50);
      } else {
        showBackground.value = false;
      }
    },
  );

  onUnmounted(() => {
    clearTimeout(_timer);
    _timer = null;
  });

  return {
    hoveredCinemaHall,
    hoveredCinemaHallPopup,
    showBackground,
    setActiveCinemaHallTooltip,
    closeCinemaHallTooltip,
    holdCinemaHallTooltip,
  };
};
