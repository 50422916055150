<script setup lang="ts">
import { onMounted } from "#imports";
import ComponentBuilder from "~/components/ComponentBuilder.vue";
import { useFetchCommonContentData } from "~/composables/useFetchCraftData";

const props = defineProps({
  identificator: {
    type: String,
    required: true,
  },
  isKiosk: {
    type: Boolean,
    default: false,
  },
});
const items = ref([]);

if (props.identificator) {
  const previewToken = useState("previewToken", () => null);
  const { data, refresh } = await useFetchCommonContentData({
    id: props.identificator,
  });
  if (data.value?.entry?.commonContentBuilder) {
    items.value = data.value.entry.commonContentBuilder;
  }
  onMounted(async () => {
    if (previewToken?.value !== null) {
      await refresh();
    }
  });
}
</script>

<template>
  <ComponentBuilder :items="items" :is-kiosk="isKiosk" />
</template>
