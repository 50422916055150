<template>
  <div class="absolute pointer-events-none" :class="getVariantClasses" />
</template>

<script lang="ts">
import { defineComponent, computed } from "#imports";

const VARIANTS = {
  top: {
    classes: "hero-block-gradient-top",
  },
  left: {
    classes: "hero-block-gradient-left",
  },
  right: {
    classes: "hero-block-gradient-right",
  },
  bottom: {
    classes: "hero-block-gradient-bottom",
  },
};

export default defineComponent({
  props: {
    variant: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const getVariantClasses =
      computed(() => VARIANTS?.[props.variant]?.classes) ?? "";

    return {
      getVariantClasses,
    };
  },
});
</script>

<style lang="postcss">
.hero-block-gradient-top {
  @apply left-0 top-0 right-0 w-full;
  bottom: 80%;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.0001) 0%,
    rgba(0, 0, 0, 0.8) 100%
  );
  mix-blend-mode: normal;
}
.hero-block-gradient-left {
  @apply left-0 top-0 right-1/2 h-full opacity-80;
  bottom: 15%;
  background: linear-gradient(270deg, rgba(0, 0, 0, 0.0001) 0%, #020920 88.15%);
  mix-blend-mode: normal;
}
.hero-block-gradient-right {
  @apply left-0 right-1/2 top-0 md:right-0 md:left-1/2 h-full opacity-80;
  background: linear-gradient(270deg, rgba(0, 0, 0, 0.0001) 0%, #020920 88.15%);
  mix-blend-mode: normal;
}
.hero-block-gradient-bottom {
  @apply left-0 bottom-0 right-0 w-full h-full;
  top: 29%;
  background: linear-gradient(
    180deg,
    rgba(1, 9, 32, 0.0001) 0%,
    #020920 53.38%,
    #020920 85.51%,
    rgba(2, 9, 32, 0.0001) 100%
  );
}

@screen md {
  .hero-block-gradient-top {
    @apply opacity-50;
    bottom: 75%;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.0001) 29.28%,
      rgba(0, 0, 0, 0.8) 83.4%,
      #000000 100%
    );
  }
  .hero-block-gradient-left {
    @apply opacity-90;
    bottom: 20%;
    right: 41%;
    background: linear-gradient(
      270deg,
      rgba(0, 0, 0, 0.0001) 0%,
      #020920 88.15%
    );
  }
  .hero-block-gradient-right {
    @apply right-0;
    bottom: 20%;
    left: 41%;
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.0001) 0%,
      #020920 88.15%
    );
  }
  .hero-block-gradient-bottom {
    top: 42%;
    height: 80%;
    background: linear-gradient(
      180deg,
      rgba(1, 9, 32, 0.0001) 0%,
      #020920 61.33%,
      #020920 77.39%,
      rgba(2, 9, 32, 0.0001) 100%
    );
  }
}

@screen xl {
  .hero-block-gradient-top {
    bottom: 72%;
  }
  .hero-block-gradient-left {
    right: 44%;
    bottom: 19%;
  }
  .hero-block-gradient-right {
    left: 44%;
    bottom: 19%;
  }
  .hero-block-gradient-bottom {
    top: 47%;
    height: 75%;
  }
}

@screen 3xl {
  .hero-block-gradient-top {
    bottom: 71%;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.0001) 29.28%,
      rgba(0, 0, 0, 0.8) 83.4%,
      #000000 100%
    );
  }
  .hero-block-gradient-bottom {
    height: 75%;
  }
}
</style>
